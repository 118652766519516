// Track if user got into website via /magazine , if so, add it into form
import {
    COOKIES_CONSENT,
    FROM_MAGAZINE_COOKIE,
    FROM_MAGAZINE_EXPIRY_DATE_COOKIE,
} from 'shared/localStorage';

export const setMagazineCookies = () => {
    localStorage.setItem(FROM_MAGAZINE_COOKIE, 'true');

    const currentDate = new Date();

    // Add 3 days to the current date
    const futureDate = new Date(currentDate);
    futureDate.setDate(currentDate.getDate() + 3);

    localStorage.setItem(
        FROM_MAGAZINE_EXPIRY_DATE_COOKIE,
        futureDate.toString(),
    );
};

export const addFromMagazineField = () => {
    const entryFromMagazineDate = localStorage.getItem(
        FROM_MAGAZINE_EXPIRY_DATE_COOKIE,
    );
    if (entryFromMagazineDate) {
        const now = new Date();
        if (now < new Date(entryFromMagazineDate)) {
            localStorage.removeItem(FROM_MAGAZINE_COOKIE);
            localStorage.removeItem(FROM_MAGAZINE_EXPIRY_DATE_COOKIE);
            return {
                fromMagazine: true,
            };
        }
    }
    return {};
};

export const deleteCookie = (cookieName: string) => {
    document.cookie =
        cookieName + '=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
};

export const isMarketingCookiesDisabled = () => {
    if (typeof window !== 'undefined') {
        return localStorage.getItem(COOKIES_CONSENT) === 'false';
    }
    return false;
};

// export const deleteGaCookies = () => {
//     deleteCookie('_ga');
//     deleteCookie('_gcl_au');
// };

export const deleteAllCookies = () => {
    document.cookie.split(';').forEach((cookie) => {
        const cookieName = cookie.split('=')[0].trim();
        console.log(cookieName);
        deleteCookie(cookieName);
    });
};
