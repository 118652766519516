import { useState, useEffect } from 'react';

const getWidth = () =>
    window.innerWidth ||
    document.documentElement.clientWidth ||
    document.body.clientWidth;

export function useCurrentWidth() {
    const [width, setWidth] = useState(0);
    const resizeListener = () => {
        globalThis.window && setWidth(getWidth());
    };

    useEffect(() => {
        resizeListener();

        window.addEventListener('resize', resizeListener);

        return () => {
            window.removeEventListener('resize', resizeListener);
        };
    }, []);

    return width;
}
