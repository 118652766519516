import { disallowedMailDomains } from 'shared/disallowedMailDomains';
import * as yup from 'yup';

export const errorMessages = {
    INVALID_EMAIL_FORMAT:
        'Invalid e-mail address format. Please enter in format name@example.com',
    REQUIRED: 'This field is required',
    PRIVACY_TEXT: 'Selecting consent is required',
};

const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export const emailValidation = yup
    .string()
    .email(errorMessages.INVALID_EMAIL_FORMAT)
    .test(
        'Should contain company mail',
        'Should contain company mail',
        (value) =>
            !disallowedMailDomains.some(
                (disallowedDomain) => value && value.includes(disallowedDomain),
            ),
    )
    .required(errorMessages.REQUIRED);

export const textValidation = yup.string().required(errorMessages.REQUIRED);

export const privacyValidation = yup
    .boolean()
    .required(errorMessages.PRIVACY_TEXT);

const phoneValidation = yup
    .string()
    .matches(phoneRegExp, 'Phone number is not valid')
    .required(errorMessages.REQUIRED);

export const contactValidation = yup.object().shape({
    name: textValidation,
    email: emailValidation,
    company: yup.string().required(),
    phone: phoneValidation,
    description: yup.string().required(),
    cb_privacyPolicy: privacyValidation,
});

export const otherInquiriesValidation = yup.object().shape({
    name: textValidation,
    email: emailValidation,
    description: yup.string().required(),
    cb_privacyPolicy: privacyValidation,
    phone: phoneValidation,
});

export const talkProjectValidation = yup.object().shape({
    name: textValidation,
    email: emailValidation,
    cb_privacyPolicy: privacyValidation,
});

export const readMoreBlogForm = yup.object().shape({
    name: textValidation,
    email: emailValidation,
    cb_privacyPolicy: privacyValidation,
});
