import {
    ClickableList,
    VerticalLine,
} from 'components/_layout/Header/Tab.styled';
import * as Common from 'components/_universal/Common';
import { navigate } from 'gatsby';
import { useTheme } from 'layout/ThemeContext';
import React from 'react';
import {
    ROUTE_OFFER_DEEPTECH_TAB,
    ROUTE_OFFER_HARDWARE_TAB,
    ROUTE_OFFER_SOFTWARE_TAB,
} from 'shared/paths';
import { B2, B4 } from 'styles/Typography.styled';
import { useBreakpoint } from 'utils/hooks/useBreakpoints';

export const TabOffer = ({ isActive }: { isActive: boolean }) => {
    const isMobile = useBreakpoint('md');
    const isLg = useBreakpoint('lg');
    const { setHeaderMenuIsOpen } = useTheme();

    return (
        <Common.Div
            mb={4}
            flex={isMobile ? 'column' : 'row'}
            justifyContent="space-between"
            ml={isLg ? 0 : 6.5}
            hidden={!isActive}
        >
            <ClickableList
                flex="column"
                gap="8px"
                mt={4}
                onClick={() => {
                    navigate(ROUTE_OFFER_SOFTWARE_TAB);
                    setHeaderMenuIsOpen(false);
                }}
            >
                <B2 mb={2}>Software</B2>
                <B4>Web Apps</B4>
                <B4>Mobile Apps</B4>
                <B4>Admin panels with advanced data analytics</B4>
                <B4>Payment integration</B4>
                <B4>UX / UI design</B4>
                <B4>Outsourcing</B4>
            </ClickableList>
            {!isMobile && <VerticalLine mr={2} ml={2} />}
            <ClickableList
                flex="column"
                gap="8px"
                mt={4}
                onClick={() => {
                    navigate(ROUTE_OFFER_DEEPTECH_TAB);
                    setHeaderMenuIsOpen(false);
                }}
            >
                <B2 mb={2}>Deep Tech</B2>
                <B4>Machine learning</B4>
                <B4>Data sets & Neural networks training</B4>
                <B4>Image & sound AI processing</B4>
                <B4>Augmented & Virtual reality solutions</B4>
                <B4>Metaverse development service</B4>
            </ClickableList>
            {!isMobile && <VerticalLine mr={2} ml={2} />}
            <ClickableList
                flex="column"
                gap="8px"
                mt={4}
                onClick={() => {
                    navigate(ROUTE_OFFER_HARDWARE_TAB);
                    setHeaderMenuIsOpen(false);
                }}
            >
                <B2 mb={2}>Hardware</B2>
                <B4>Electronics design</B4>
                <B4>Hardware prototyping</B4>
                <B4>Firmware & embedded software development</B4>
                <B4>Wireless connectivity</B4>
            </ClickableList>
        </Common.Div>
    );
};
