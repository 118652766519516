import styled, { css } from 'styled-components';
import CheckboxIcon from 'assets/images/_infoGraphics/checkboxMark.svg';

export const CheckboxInput = styled.input<{ isError?: boolean }>(
    ({ theme: { colors }, isError }) => css`
        -moz-appearance: none;
        -webkit-appearance: none;
        align-items: center;
        appearance: none;
        background: ${colors.section};
        border: 1px solid ${isError ? colors.error : colors.white};
        cursor: pointer;
        display: flex;
        height: 25px;
        width: 25px;
        justify-content: center;
        outline: none;
        position: relative;
        margin-right: 16px;
        //transition: all 0.4s ease-in-out;

        &:before {
            background-repeat: no-repeat;
            background-size: contain;
            background: transparent url(${CheckboxIcon});
            content: '';
            display: block;
            height: 25px;
            width: 25px;
            opacity: 0;
            position: absolute;
            transition: all 0.2s ease-in-out;
        }

        &:checked {
            &::before {
                opacity: 1;
                top: 0;
                transition: all 0.2s ease-in-out;
            }
        }
    `,
);
