import { useEffect, useState } from 'react';
import { breakpoints } from 'shared/breakpoints';
import { useCurrentWidth } from 'utils/hooks/useCurrentWidth';

export const useBreakpoint = (breakpoint: string) => {
    const [breakpointNumber, setBreakpointNumber] = useState(0);
    const currentWidth = useCurrentWidth();

    useEffect(() => {
        switch (breakpoint) {
            case 'xs':
                setBreakpointNumber(breakpoints.xs);
                break;
            case 'sm':
                setBreakpointNumber(breakpoints.sm);
                break;
            case 'ipadV':
                setBreakpointNumber(breakpoints.ipadV);
                break;
            case 'md':
                setBreakpointNumber(breakpoints.md);
                break;
            case 'ipadH':
                setBreakpointNumber(breakpoints.ipadH);
                break;
            case 'lg':
                setBreakpointNumber(breakpoints.lg);
                break;
            case 'xl':
                setBreakpointNumber(breakpoints.xl);
                break;
        }
    }, [breakpoint]);

    const [isBreakpoint, setIsBreakpoint] = useState(
        currentWidth <= breakpointNumber,
    );

    useEffect(() => {
        if (currentWidth <= breakpointNumber) {
            setIsBreakpoint(true);
        } else {
            setIsBreakpoint(false);
        }
    }, [currentWidth, breakpointNumber]);
    return isBreakpoint;
};
