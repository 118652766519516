import styled, { css } from 'styled-components';
import { ToastContainer } from 'react-toastify';

export const CloseButton = styled.button`
    align-self: center;
    background-color: transparent;
    border: none;
    cursor: pointer;
    display: flex;
    margin: 0;
    outline: none;
    padding: 0;
    position: absolute;
    right: 14px;
    top: 50%;
    transform: translateY(-50%);
`;

export const StyledToastContainer = styled(ToastContainer)(
    ({
        theme: { fontWeight, fontSizes, colors, letterSpacing, fontFamily },
    }) => css`
        transition: all 0.5s;
        width: max-content;

        @media (max-width: 480px) {
            margin-bottom: 16px;
            max-width: 320px;
            min-width: 300px;
        }

        .Toastify {
            &__toast {
                white-space: nowrap;
                display: flex;
                align-items: center;
                border-radius: 0;
                color: ${colors.text};
                font-family: ${fontFamily.primary};
                font-size: ${fontSizes.f13};
                font-weight: ${fontWeight.medium};
                letter-spacing: ${letterSpacing.s};
                line-height: 1;
                min-height: 50px;
                padding: 16px 44px 16px 34px;
                text-align: left;
                text-transform: uppercase;
                background: ${colors.section};

                @media (max-width: 480px) {
                    font-size: ${fontSizes.f13};
                    margin-bottom: 16px;
                    padding: 16px 72px 16px 24px;
                }
            }

            &__toast-body {
                margin: 0;
                padding: 0;

                div {
                    line-height: 1;
                }
            }
            &__toast-icon {
                display: none;
            }
        }
    `,
);
