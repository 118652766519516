export const breakpoints = {
    xs: 620,
    sm: 780,
    ipadV: 850,
    md: 1060,
    ipadH: 1180,
    lg: 1432,
    xl: 1442,
};

export const mediaQueries = {
    xs: `@media (max-width: ${breakpoints.xs}px)`,
    sm: `@media (max-width: ${breakpoints.sm}px)`,
    ipadV: `@media (max-width: ${breakpoints.ipadV}px)`,
    md: `@media (max-width: ${breakpoints.md}px)`,
    ipadH: `@media (max-width: ${breakpoints.ipadH}px)`,
    lg: `@media (max-width: ${breakpoints.lg}px)`,
    xl: `@media (min-width: ${breakpoints.xl}px)`,
};
