import styled, { css } from 'styled-components';

import {
    customProps,
    ICustomStyledProps,
} from 'components/_universal/commonStyles';

export const Div = styled.div<ICustomStyledProps>(
    ({ theme }) => css`
        // prevent any section from running out of containers
        max-width: 100%;

        ${customProps};
    `,
);

export const Svg = styled.img<ICustomStyledProps>(
    ({ theme, w, h, onClick }) => css`
        ${customProps};
        user-select: none;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
    `,
);
