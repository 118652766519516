import * as Common from 'components/_universal/Common';
import { Link } from 'gatsby';
import { mediaQueries } from 'shared/breakpoints';
import styled, { css } from 'styled-components';
import { B1, B4 } from 'styles/Typography.styled';

export const HeaderWrapper = styled.div<{
    backgroundUrl?: string;
    headerIsVisible: boolean;
    isSticky: boolean;
    headerMenuIsOpen?: boolean;
}>(
    ({
        backgroundUrl,
        headerIsVisible,
        isSticky,
        headerMenuIsOpen,
        theme: { colors, gradients },
    }) =>
        css`
            position: fixed;
            left: 0;
            top: ${headerIsVisible ? 0 : '-112px'};
            display: flex;
            flex-direction: column;
            width: 100vw;
            max-width: 100vw;
            min-height: ${headerMenuIsOpen ? '300px' : 'auto'};
            height: ${headerMenuIsOpen ? 'auto' : '112px'};
            overflow-y: ${headerMenuIsOpen && 'scroll'};
            overflow-x: hidden;
            background-size: cover;
            color: ${colors.white};
            padding: ${headerMenuIsOpen
                ? '28px 150px 28px 156px'
                : '28px 156px'};
            transition: height 0.3s ease-in-out,
                background-color 0.3s ease-in-out;
            z-index: 9999;
            background: ${headerMenuIsOpen || isSticky
                ? colors.section
                : 'transparent'};

            ${mediaQueries.md} {
                padding: 28px 32px;
                height: ${headerMenuIsOpen ? '100svh' : '112px'};
            }

            ${(headerMenuIsOpen || isSticky) &&
            `
            -webkit-box-shadow: 3px 2px 10px 6px rgba(0, 0, 0, 0.26);
            -moz-box-shadow: 3px 2px 10px 6px rgba(0, 0, 0, 0.26);
            box-shadow: 3px 2px 10px 6px rgba(0, 0, 0, 0.26);`};
        `,
);

export const NavWrapper = styled(Common.Div)<{ isVisible?: boolean }>(
    ({ isVisible }) =>
        css`
            display: flex;
            flex-direction: column;
            opacity: ${isVisible ? 1 : 0};
            transition: opacity 0.3s ease-in-out;
        `,
);

export const ExpandableDiv = styled(Common.Div)<{
    isOpen?: boolean;
    vHeight?: string;
    hHeight?: string;
}>(
    ({ isOpen }) =>
        css`
            transition: transform 0.2s ease-in-out;
            scroll-behavior: smooth;
            overflow: hidden;
            transform: scaleY(${isOpen ? 1 : 0});
            transform-origin: top;
        `,
);

export const UeLogo = styled.img`
    max-width: 126px;
    height: 28px;
`;

export const LinkText = styled(B4)(
    ({ theme: { colors } }) =>
        css`
            align-items: center;
            display: flex;
            text-decoration: none;
            color: white;
            cursor: pointer;

            &:visited {
                text-decoration: none;
            }

            &:hover {
                color: ${colors.hoverBlue};
            }
        `,
);

export const StyledLink = styled(Link)(
    ({ theme: { colors } }) =>
        css`
            p {
                align-items: center;
                display: flex;
                text-decoration: none;
                color: white;
                cursor: pointer;

                &:visited {
                    text-decoration: none;
                }

                &:hover {
                    color: ${colors.hoverBlue};
                }
            }
        `,
);

export const ContactUsText = styled(B4)(
    ({ theme: { colors } }) =>
        css`
            text-decoration: underline;
            text-decoration-color: ${colors.hoverBlue};
            text-underline-offset: 3px;

            &:hover {
                color: ${colors.hoverBlue};
            }
        `,
);

export const HeaderMenuItem = styled(B1)<{ isActive?: boolean }>(
    ({ theme: { colors }, isActive }) =>
        css`
            align-items: center;
            display: flex;
            text-decoration: none;
            color: ${isActive ? colors.hoverBlue : colors.white};
            cursor: pointer;

            &:visited {
                text-decoration: none;
            }

            &:hover {
                color: ${colors.hoverBlue};
            }
        `,
);

export const IconLine = styled.div`
    height: 4px;
    transform-origin: 4.5px;
    transition: all 0.3s linear;
    width: 30px;
`;

export const BurgerButton = styled.div<{ isOpen: boolean }>(
    ({ theme: { colors }, isOpen }) => css`
        align-items: flex-end;
        background: transparent;
        border: none;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        padding: 0;
        pointer-events: all;
        right: 20px;
        top: 36px;
        z-index: 999;

        &:focus {
            outline: none;
        }

        ${IconLine} {
            background: ${colors.white};

            :first-child,
            :nth-child(2),
            :nth-child(3) {
                width: 42px;
                height: 2px;
            }

            :first-child {
                transform: ${isOpen ? 'rotate(45deg)' : 'rotate(0)'};
            }

            :nth-child(2) {
                margin: 10px 0;
                opacity: ${isOpen ? 0 : 1};
            }

            :nth-child(3) {
                transform: ${isOpen ? 'rotate(-45deg)' : 'rotate(0)'};
            }
        }
    `,
);

export const NavLink = styled(Link)<{ isActive?: boolean }>(
    ({ theme: { colors } }) =>
        css`
            p {
                &:hover {
                    color: ${colors.hoverBlue};
                }
            }
        `,
);

export const OverlayMask = styled(Common.Div)`
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.55);
    z-index: 999;
`;
