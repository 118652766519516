import { Navigation } from 'components/_layout/Navigation/Navigation';
import * as Common from 'components/_universal/Common';
import React, { ReactNode } from 'react';
import { mediaQueries } from 'shared/breakpoints';
import styled, { css } from 'styled-components';
import { H2 } from 'styles/Typography.styled';

export interface SectionProps {
    title?: string;
    children?: ReactNode;
    variant?: 'primary' | 'secondary';
    isSmall?: boolean;
    className?: string;
    id?: string;
    isFirst?: boolean;
    withBreadCrumbs?: boolean;
}

export const Wrapper = styled(Common.Div)<{
    variant?: 'primary' | 'secondary';
    className?: string;
    isHeader?: boolean;
    isSmall?: boolean;
}>(
    ({ theme: { colors }, variant, isHeader, isSmall }) =>
        css`
            background: ${variant === 'primary'
                ? colors.section
                : colors.background};
            display: flex;
            flex-direction: column;
            margin-top: ${isHeader ? '28px' : 0};
            position: relative;
            padding: 96px 156px;
            width: 100%;

            ${isSmall &&
            `
            height: 92px;
            padding: 20px 156px;
            `}

            ${mediaQueries.md} {
                padding: 44px 32px;
            }
        `,
);

export const Section = ({
    title,
    children,
    variant = 'primary',
    className = '',
    id,
    isFirst,
    withBreadCrumbs = false,
    isSmall,
}: SectionProps) => {
    return (
        <>
            <Wrapper
                variant={variant}
                className={className}
                id={id}
                isHeader={isFirst}
                isSmall={isSmall}
            >
                {withBreadCrumbs && <Navigation />}
                {title && <H2>{title}</H2>}
                {children}
            </Wrapper>
        </>
    );
};
