export const SPACING_PX = 8;

const defaultTheme = {
    colors: {
        backgroundGrey: '#F6F9FF',
        black: '#000000',
        border: '#efeff4',
        error: ' #C52323',
        grey: '#898892',
        lightGrey: '#EEF3FB',
        placeholder: '#C4C4C4',
        secondaryBlue: '#6DD2FF',
        white: '#FFFFFF',

        // new colors
        background: '#2E2E2E',
        section: '#424242',
        tableRow: 'rgba(66,66,66,0.45)',
        buttons: 'rgba(255, 255, 255, 0.20)',
        text: '#F1F1F1',
        headerText: '#B3B3B3',
        textSecondary: 'rgba(179, 179, 179, 1)',
        boatBlue: '#6dd2ff',
        mainBlue: '#3DA2EB',
        hoverBlue: 'rgba(109, 210, 255, 1)',
        purple: '#364CC1',
        majenta: '#A435FB',
        greylikeText: 'rgba(241, 241, 241, 1)',
    },
    gradients: {
        primary: `linear-gradient(270deg, #2D0093 0%, #6DD2FF 118%)`,
        orange: 'linear-gradient(270deg, #FF7E19 0%, #8A2FFD 98%)',
        orangeHover: 'linear-gradient(270deg, #ff7e19 -45.94%, #8a2ffd 40%)',
        bluePurple: 'linear-gradient(270deg, #8A2FFD 0%, #2D00FF 98%)',
        purple: 'linear-gradient(270deg, #8A2FFD 0%, #2D00FF 135%);',

        // new gradient
        teal: 'linear-gradient(90deg, rgba(134, 201, 216, 0.65) 0%, rgba(134, 201, 216, 0.00) 100%);',
    },
    fontSizes: {
        f8: '8px',
        f9: '9px',
        f10: '10px',
        f11: '11px',
        f12: '12px',
        f13: '13px',
        f14: '14px',
        f15: '15px',
        f16: '16px',
        f17: '17px',
        f18: '18px',
        f20: '20px',
        f24: '24px',
        f26: '26px',
        f32: '32px',
        f36: '36px',
        f42: '42px',
        f54: '54px',

        // new font sizes
        h1: '60px',
        h2: '50px',
        h3: '32px',
        h4: '28px',
        b0: '24px',
        b1: '20px',
        b2: '16px',
        b3: '14px',
        b4: '12px',
    },
    fontWeight: {
        light: 300,
        regular: 400,
        medium: 500,
        semiBold: 600,
        bold: 700,
    },
    fontFamily: {
        primary: `'Inter', sans-serif`,
        secondary: `'Work Sans', sans-serif`,

        // new fonts
        heading: 'mplusLatin, sans-serif',
        body: 'Inter, sans-serif',
    },
    letterSpacing: {
        xxs: '-0.005em',
        xs: '0.005em',
        s: '0.01em',
        m: '0.015em',
        l: '0.02em',
        xl: '0.05em',
    },
    containerWidth: {
        default: '1150px',
        lg: '960px',
        md: '720px',
        sm: '540px',
        xs: '100%',
    },
    spacing: (n: number) => `${n * SPACING_PX}px`,
};

export default defaultTheme;
