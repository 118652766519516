import * as Common from 'components/_universal/Common';
import React from 'react';

import styled, { css } from 'styled-components';

export const ToggleDot = styled(Common.Div)(
    ({ theme: { colors } }) =>
        css`
            background-color: ${colors.section};
            width: 13px;
            height: 13px;
            border-radius: 10px;
        `,
);

export const StyledButton = styled(Common.Div)<{
    isOn?: boolean;
    isDisabled?: boolean;
}>(
    ({ isOn, isDisabled }) =>
        css`
            margin-top: 8px;
            background: ${isOn && !isDisabled ? '#86c9d8' : '#6d6d6d'};
            min-width: 30px;
            height: 17px;
            border-radius: 10px;
            display: flex;
            justify-content: ${isOn ? 'flex-end' : 'flex-start'};
            padding: 2px;
        `,
);

interface IToggleButtonProps {
    onClick?: () => void;
    className?: string;
    isToggled?: boolean;
    isDisabled?: boolean;
}

export const ToggleButton = ({
    onClick,
    isToggled = false,
    className = '',
    isDisabled,
}: IToggleButtonProps) => {
    return (
        <StyledButton
            onClick={onClick}
            className={className}
            isOn={isToggled}
            isDisabled={isDisabled}
        >
            <ToggleDot />
        </StyledButton>
    );
};
