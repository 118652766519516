import { ConsentToCookies } from 'components/_modals/CookiesLaw/ConsentToCookies';
import React, { useEffect, useState } from 'react';
import * as Common from 'components/_universal/Common';
import {
    CookieActionText,
    CookieButton,
    CookieLink,
    CookieOuterWrapper,
    ManageButton,
    Wrapper,
} from 'components/_modals/CookiesLaw/CookiesLaw.styled';
import { COOKIES_CLICKED, COOKIES_CONSENT } from 'shared/localStorage';
import { ROUTE_PRIVACY_POLICY } from 'shared/paths';
import { B2, B3 } from 'styles/Typography.styled';

export const CookiesLaw = () => {
    const [isSlideUp, setIsSlideUp] = useState(false);
    const [isConsentOpen, setIsConsentOpen] = useState(false);

    useEffect(() => {
        if (typeof window !== 'undefined') {
            let areCookiesClicked = localStorage.getItem('areCookiesClicked');

            //@ts-ignore
            if (!JSON.parse(areCookiesClicked)) {
                setIsSlideUp(true);
            }
        }
    }, []);

    const handleCookiesAccept = () => {
        setIsSlideUp(false);
        localStorage.setItem(COOKIES_CONSENT, 'true');
        localStorage.setItem(COOKIES_CLICKED, 'true');
    };

    const handleManageCookies = () => {
        setIsConsentOpen(true);
        setIsSlideUp(false);
    };

    return (
        <>
            <CookieOuterWrapper isSlideUp={isSlideUp}>
                <Wrapper>
                    <Common.Div>
                        <B2 mb={3}>We value your privacy.</B2>
                        <B3>
                            We use cookies on our site to enhance your
                            experience, improve our site, and to deliver content
                            and ads that are relevant to you. You can choose to
                            accept all cookies by clicking Accept All, or you
                            can manage them individually by clicking
                            <CookieActionText onClick={handleManageCookies}>
                                Manage cookies
                            </CookieActionText>
                            , where you can read more. Please find more details
                            about our Privacy Policy
                            <CookieLink
                                to={ROUTE_PRIVACY_POLICY}
                                target="_blank"
                            >
                                in here.
                            </CookieLink>
                        </B3>
                    </Common.Div>
                    <Common.Div flex="row" justifyContent="flex-end" gap="16px">
                        <CookieButton
                            id={'acceptButton'}
                            onClick={handleCookiesAccept}
                        >
                            Accept All
                        </CookieButton>
                        <ManageButton onClick={handleManageCookies}>
                            Manage cookies
                        </ManageButton>
                    </Common.Div>
                </Wrapper>
            </CookieOuterWrapper>
            {isConsentOpen && (
                <ConsentToCookies
                    setIsConsentOpen={setIsConsentOpen}
                    setIsSlideUp={setIsSlideUp}
                />
            )}
        </>
    );
};
