import { createContext, useContext } from 'react';

export interface ThemeContextType {
    headerMenuIsOpen: boolean;
    setHeaderMenuIsOpen: (headerMenuIsOpen: boolean) => void;
    isScrollDisabled: boolean;
    setIsScrollDisabled: (headerMenuIsOpen: boolean) => void;
    activeCategory: string;
    setActiveCategory: (activeCategories: string) => void;
    blogTagsSelected: string[];
    setBlogTagsSelected: (tags: string[]) => void;
    caseStudiesTagsSelected: string[];
    setCaseStudiesTagsSelected: (tags: string[]) => void;
    headerIsVisible: boolean;
    setHeaderIsVisible: (headerIsVisible: boolean) => void;
}

export const ThemeContext = createContext<ThemeContextType>({
    headerMenuIsOpen: false,
    isScrollDisabled: false,
    setIsScrollDisabled: () => undefined,
    setHeaderMenuIsOpen: () => undefined,
    activeCategory: 'all',
    setActiveCategory: () => undefined,
    blogTagsSelected: [],
    caseStudiesTagsSelected: [],
    setBlogTagsSelected: () => undefined,
    setCaseStudiesTagsSelected: () => undefined,
    headerIsVisible: true,
    setHeaderIsVisible: () => undefined,
});

export const useTheme = () => useContext(ThemeContext);
