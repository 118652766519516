import HeaderBg from 'assets/images/_backgrounds/header_bg.svg';
import {
    ContactUsText,
    HeaderWrapper,
    OverlayMask,
} from 'components/_layout/Header/Header.styled';
import { LogoWithMenu } from 'components/_layout/Header/LogoWithMenu';
import { TabIndustries } from 'components/_layout/Header/TabIndustries';
import { TabOffer } from 'components/_layout/Header/TabOffer';
import { VerticalMenu } from 'components/_layout/Header/VerticalMenu';
import { SocialMediaIcons } from 'components/_layout/SocialMediaIcons';
import * as Common from 'components/_universal/Common';
import { navigate } from 'gatsby';
import { useTheme } from 'layout/ThemeContext';
import React, { useEffect, useRef, useState } from 'react';
import { ROUTE_CONTACT } from 'shared/paths';
import { useBreakpoint } from 'utils/hooks/useBreakpoints';
import { useOnClickOutside } from 'utils/hooks/useClickOutside';

export interface HeaderProps {
    headerIsVisible: boolean;
    isStickyFromStart?: boolean;
}

export type THeaderTabType =
    | 'OFFER'
    | 'INDUSTRIES'
    | 'CASE_STUDIES'
    | 'COMPANY'
    | null;

export const Header = ({
    headerIsVisible,
    isStickyFromStart = false,
}: HeaderProps) => {
    const { headerMenuIsOpen, setHeaderMenuIsOpen } = useTheme();
    const [scrollPositionY, setScrollPositionY] = useState(false);
    const isMd = useBreakpoint('md');
    const isSm = useBreakpoint('sm');
    const headerRef = useRef(null);
    const isLg = useBreakpoint('lg');
    const [activeTab, setActiveTab] = useState<THeaderTabType>(null);

    // preselect offer tab
    useEffect(() => {
        if (!isSm) {
            setActiveTab('OFFER');
        } else {
            setActiveTab(null);
        }
    }, [isSm]);

    useOnClickOutside(headerRef, () => setHeaderMenuIsOpen(false));

    const isSticky = () => {
        const scrollTop = window.scrollY;
        scrollTop >= (isMd ? 50 : 100)
            ? setScrollPositionY(true)
            : setScrollPositionY(false);
    };

    useEffect(() => {
        window.addEventListener('scroll', isSticky);

        return () => {
            window.removeEventListener('scroll', isSticky);
        };
    });

    const handleTabSelect = (tab: THeaderTabType) => {
        if (activeTab === tab && isLg) {
            setActiveTab(null);
        } else {
            setActiveTab(tab);
        }
    };

    return (
        <>
            <HeaderWrapper
                backgroundUrl={HeaderBg}
                headerMenuIsOpen={headerMenuIsOpen}
                headerIsVisible={headerIsVisible}
                isSticky={
                    isStickyFromStart ? isStickyFromStart : scrollPositionY
                }
                ref={headerRef}
            >
                <LogoWithMenu
                    activeTab={activeTab}
                    handleTabSelect={handleTabSelect}
                />
                <div>
                    <VerticalMenu
                        isVisible={isLg && headerMenuIsOpen}
                        activeTab={activeTab}
                        handleTabSelect={handleTabSelect}
                    />
                    <Common.Div>
                        <TabOffer
                            isActive={
                                activeTab === 'OFFER' &&
                                !isLg &&
                                headerMenuIsOpen
                            }
                        />
                        <TabIndustries
                            isActive={
                                activeTab === 'INDUSTRIES' &&
                                !isLg &&
                                headerMenuIsOpen
                            }
                        />
                    </Common.Div>
                </div>
                <Common.Div flex="row" flexGrow={1} />
                <div>
                    {headerMenuIsOpen && (
                        <Common.Div
                            flex="row"
                            justifyContent="space-between"
                            ml={isLg ? 0 : 6.5}
                            mt={8}
                        >
                            <ContactUsText
                                onClick={() => {
                                    setHeaderMenuIsOpen(false);
                                    navigate(ROUTE_CONTACT);
                                }}
                            >
                                Contact us
                            </ContactUsText>
                            <SocialMediaIcons />
                        </Common.Div>
                    )}
                </div>
            </HeaderWrapper>
            {!isMd && headerMenuIsOpen && <OverlayMask />}
        </>
    );
};
