import React from 'react';
import { B2 } from 'styles/Typography.styled';
import {
    Input,
    InputWrapper,
} from 'src/components/_inputs/TextInput/TextInput.styled';
import { ErrorMessage } from 'components/_inputs/ErrorMessage/ErrorMessage';
import {
    Control,
    FieldError,
    FieldErrors,
    useController,
} from 'react-hook-form';

interface TextInputProps {
    control: Control;
    errors?: FieldError | FieldErrors;
    name: string;
    onClick?: () => void;
    placeholder?: string;
    type: 'text' | 'email';
    label?: string;
    className?: string;
}

export const TextInput = ({
    control,
    errors,
    name,
    onClick,
    placeholder = '',
    type,
    label,
    className,
}: TextInputProps) => {
    const {
        field: { onChange, value },
    } = useController({
        name,
        control,
    });

    return (
        <InputWrapper className={className}>
            {label && <B2 mb={2}>{label}</B2>}
            <Input
                id={name}
                isError={!!errors}
                name={name}
                onChange={onChange}
                onClick={onClick}
                onFocus={onClick}
                placeholder={placeholder}
                type={type}
                value={value}
            />
            {errors && <ErrorMessage error={errors} />}
        </InputWrapper>
    );
};
