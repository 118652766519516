import React from 'react';
import { B2 } from 'styles/Typography.styled';
import {
    TextArea,
    TextareaWrapper,
} from 'src/components/_inputs/Textarea/Textarea.styled';
import { ErrorMessage } from 'components/_inputs/ErrorMessage/ErrorMessage';
import { Control, useController } from 'react-hook-form';
import { Fade } from 'react-awesome-reveal';

interface TextareaProps {
    className?: string;
    control: Control;
    errors?: any;
    maxLength?: number;
    name: string;
    onClick?: () => void;
    placeholder?: string;
    label?: string;
}

export const Textarea = ({
    className,
    control,
    errors,
    name,
    onClick,
    placeholder,
    label,
}: TextareaProps) => {
    const {
        field: { onChange, value },
    } = useController({
        name,
        control,
    });

    return (
        <TextareaWrapper>
            {label && <B2 mb={2}>{label}</B2>}
            <TextArea
                className={className}
                id={name}
                isError={!!errors}
                onChange={onChange}
                onClick={onClick}
                onFocus={onClick}
                placeholder={placeholder}
                value={value}
            />
            {errors && (
                <Fade direction="up" triggerOnce>
                    <ErrorMessage error={errors} />
                </Fade>
            )}
        </TextareaWrapper>
    );
};
