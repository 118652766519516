import React from 'react';

export const TwitterIcon = () => (
    <svg
        width="15"
        height="15"
        viewBox="0 0 15 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5.61925 7.60754L0 0.625H4.45084L7.91956 4.94069L11.6253 0.644431H14.0766L9.10476 6.4153L15 13.75H10.5624L6.80653 9.08286L2.79672 13.7371H0.332152L5.61925 7.60754ZM11.2092 12.4563L2.72911 1.91874H3.80353L12.273 12.4563H11.2092Z"
            fill="#767676"
        />
    </svg>
);

export const FacebookIcon = () => (
    <svg
        width="15"
        height="15"
        viewBox="0 0 15 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g id="003-facebook-logo 1" opacity="0.6">
            <g id="Group">
                <path
                    id="Vector"
                    d="M11.2496 0.003121L9.30447 0C7.11916 0 5.70692 1.44892 5.70692 3.69152V5.39356H3.75117C3.58217 5.39356 3.44531 5.53057 3.44531 5.69957V8.16563C3.44531 8.33463 3.58232 8.47149 3.75117 8.47149H5.70692V14.6941C5.70692 14.8631 5.84378 15 6.01278 15H8.56448C8.73348 15 8.87034 14.863 8.87034 14.6941V8.47149H11.1571C11.3261 8.47149 11.4629 8.33463 11.4629 8.16563L11.4639 5.69957C11.4639 5.61843 11.4316 5.54071 11.3743 5.48329C11.317 5.42586 11.239 5.39356 11.1579 5.39356H8.87034V3.95072C8.87034 3.25723 9.03559 2.90518 9.93896 2.90518L11.2493 2.90472C11.4181 2.90472 11.555 2.7677 11.555 2.59886V0.308979C11.555 0.140289 11.4183 0.0034331 11.2496 0.003121Z"
                    fill="#A3A3A3"
                />
            </g>
        </g>
    </svg>
);

export const LinkedInIcon = () => (
    <svg
        width="15"
        height="15"
        viewBox="0 0 15 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g
            id="001-linkedin-letters 1"
            opacity="0.6"
            clipPath="url(#clip0_6955_10084)"
        >
            <g id="Group">
                <g id="Group_2">
                    <path
                        id="Vector"
                        d="M3.40818 4.99023H0.185547V14.6679H3.40818V4.99023Z"
                        fill="#A3A3A3"
                    />
                    <path
                        id="Vector_2"
                        d="M13.9845 5.87413C13.3076 5.13517 12.4123 4.76562 11.2991 4.76562C10.8889 4.76562 10.5161 4.81608 10.1807 4.91705C9.8455 5.01795 9.56235 5.15953 9.33116 5.34184C9.10011 5.52415 8.91609 5.6934 8.77947 5.84958C8.64956 5.99792 8.52253 6.17072 8.39857 6.3662V4.99008H5.18555L5.1954 5.45889C5.202 5.77149 5.20525 6.73497 5.20525 8.34947C5.20525 9.9641 5.19875 12.0702 5.18568 14.6678H8.39857V9.26735C8.39857 8.9354 8.43418 8.67178 8.50594 8.4763C8.64276 8.1441 8.84925 7.86598 9.1261 7.64146C9.40292 7.41667 9.74623 7.30437 10.1565 7.30437C10.7162 7.30437 11.1282 7.49804 11.3919 7.88541C11.6555 8.27275 11.7873 8.80826 11.7873 9.49187V14.6675H15.0002V9.12103C15 7.6951 14.6617 6.61286 13.9845 5.87413Z"
                        fill="#A3A3A3"
                    />
                    <path
                        id="Vector_3"
                        d="M1.81637 0.332031C1.27608 0.332031 0.838289 0.490023 0.502912 0.805699C0.167569 1.12144 0 1.52007 0 2.00194C0 2.47708 0.162746 2.87434 0.488272 3.19326C0.813696 3.51222 1.24344 3.67179 1.77728 3.67179H1.79681C2.34374 3.67179 2.78488 3.51236 3.12012 3.19326C3.45536 2.87434 3.61971 2.47722 3.61325 2.00194C3.60675 1.5201 3.44062 1.12144 3.11519 0.805699C2.78981 0.489886 2.35671 0.332031 1.81637 0.332031Z"
                        fill="#A3A3A3"
                    />
                </g>
            </g>
        </g>
        <defs>
            <clipPath id="clip0_6955_10084">
                <rect width="15" height="15" fill="white" />
            </clipPath>
        </defs>
    </svg>
);

export const GoodFirmsIcon = () => (
    <svg width="15" height="15" viewBox="0 0 15 15" fill="none">
        <g clipPath="url(#clip0_7672_357)">
            <mask
                id="mask0_7672_357"
                // style="mask-type:luminance"
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="15"
                height="15"
            >
                <path d="M0 0H15V15H0V0Z" fill="#A3A3A3" />
            </mask>
            <g mask="url(#mask0_7672_357)">
                <path
                    d="M6.175 5.2749C6.175 5.2749 5.3125 5.1999 5.3125 5.8999V9.20615H10.8375V10.2062C10.8391 10.2726 10.8274 10.3386 10.8029 10.4004C10.7784 10.4621 10.7417 10.5183 10.695 10.5655C10.6484 10.6128 10.5926 10.6502 10.5312 10.6754C10.4698 10.7006 10.4039 10.7132 10.3375 10.7124H5.3375V15.0187H11.3625C11.3625 15.0187 15 14.4624 15 10.7312V5.2749H6.175ZM14.925 -0.0375977H4.01875C0.0749999 0.899902 0 4.1249 0 4.1249V15.0374H4.1625V5.13115C4.16521 4.89273 4.24997 4.66252 4.4025 4.47925C4.55503 4.29599 4.76603 4.17085 5 4.1249H14.925V-0.0375977Z"
                    fill="#A3A3A3"
                />
            </g>
        </g>
        <defs>
            <clipPath id="clip0_7672_357">
                <rect width="15" height="15" fill="#A3A3A3" />
            </clipPath>
        </defs>
    </svg>
);
