import IconMinimalize from 'assets/images/_icons/iconMinimalize.png';
import IconArrowMaximize from 'assets/images/_infoGraphics/arrow.svg';
import {
    TalkContent,
    TalkFixedTray,
    TalkModal,
    OverlayMask,
} from 'components/_modals/TalkProjectPopup.styled';
import * as Common from 'components/_universal/Common';
import { useTheme } from 'layout/ThemeContext';
import React, { useEffect, useRef, useState } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { TalkProjectForm } from 'sections/_universal/TalkProjectForm';
import { B1, B2, H3 } from 'styles/Typography.styled';
import { useOnClickOutside } from 'utils/hooks/useClickOutside';

export const TalkProjectPopup = () => {
    const [popupOpened, setPopupOpened] = useState(false);
    const [isPageScrolledDown, setIsPageScrolledDown] = useState(false);
    const { setIsScrollDisabled } = useTheme();
    const popupRef = useRef(null);

    useEffect(() => {
        function handleScroll() {
            const isPageBottom =
                window.innerHeight + window.scrollY >=
                document.body.offsetHeight;
            setIsPageScrolledDown(isPageBottom);
        }

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useOnClickOutside(popupRef, () => {
        setIsScrollDisabled(false);
        setPopupOpened(false);
    });

    // auto-open for user
    useEffect(() => {
        let t = setTimeout(() => {
            if (!popupOpened && !localStorage.getItem('talkProjectOpened')) {
                localStorage.setItem('talkProjectOpened', 'yes');
                setPopupOpened(true);
                setIsScrollDisabled(true);
            }
        }, 20000);
        return () => {
            clearTimeout(t);
        };
    }, []);

    const handleOpenPopup = () => {
        localStorage.setItem('talkProjectOpened', 'yes');
        setIsScrollDisabled(true);
        setPopupOpened(true);
    };

    const handleClosePopup = () => {
        setIsScrollDisabled(false);
        setPopupOpened(false);
    };

    return (
        <>
            {!popupOpened && (
                <TalkFixedTray
                    onClick={handleOpenPopup}
                    isPageScrolledDown={isPageScrolledDown}
                >
                    <TalkContent>
                        <B2>Let's Discuss!</B2>
                        <Common.Svg src={IconArrowMaximize} />
                    </TalkContent>
                </TalkFixedTray>
            )}
            <TalkModal ref={popupRef} hidden={!popupOpened}>
                <Common.Div flex="row" justifyContent="space-between">
                    <H3 mb={5} mt={3}>
                        Embark on a tech adventure!
                    </H3>
                    <Common.Div onClick={handleClosePopup}>
                        <Common.Svg src={IconMinimalize} />
                    </Common.Div>
                </Common.Div>

                <TalkProjectForm handleClosePopup={handleClosePopup} />
            </TalkModal>
            <OverlayMask hidden={!popupOpened} />
        </>
    );
};
