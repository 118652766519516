import { LinkText, StyledLink } from 'components/_layout/Header/Header.styled';
import * as Common from 'components/_universal/Common';
import { navigate } from 'gatsby';
import { useTheme } from 'layout/ThemeContext';
import React from 'react';
import {
    EXTERNAL_ROUTE_ONBOARDING,
    ROUTE_ABOUT,
    ROUTE_BLOG,
    ROUTE_CAREER,
} from 'shared/paths';
import { B4 } from 'styles/Typography.styled';
import { useBreakpoint } from 'utils/hooks/useBreakpoints';

export const TabCompany = ({ isActive }: { isActive: boolean }) => {
    const { setHeaderMenuIsOpen } = useTheme();
    const isLg = useBreakpoint('lg');

    return (
        <Common.Div
            mt={4}
            flex={'column'}
            gap="16px"
            position={!isLg ? 'absolute' : 'initial'}
            hidden={!isActive}
        >
            <StyledLink to={ROUTE_ABOUT}>
                <B4 onClick={() => setHeaderMenuIsOpen(false)}>About</B4>
            </StyledLink>
            <StyledLink to={ROUTE_CAREER}>
                <B4 onClick={() => setHeaderMenuIsOpen(false)}>Career</B4>
            </StyledLink>
            <StyledLink to={ROUTE_BLOG}>
                <B4 onClick={() => setHeaderMenuIsOpen(false)}>Blog</B4>
            </StyledLink>
            <LinkText onClick={() => navigate(EXTERNAL_ROUTE_ONBOARDING)}>
                Onboarding
            </LinkText>
        </Common.Div>
    );
};
