import styled, { css } from 'styled-components';

export const TextArea = styled.textarea<{
    isError?: boolean;
}>(
    ({
        theme: { colors, fontFamily, fontSizes, fontWeight, letterSpacing },
        isError,
    }) => css`
        background: none;
        border: 1px solid ${isError ? colors.error : colors.white};
        color: ${colors.white};
        font-family: ${fontFamily.secondary};
        font-size: ${fontSizes.f16};
        font-weight: ${fontWeight.regular};
        letter-spacing: ${letterSpacing.s};
        line-height: 20px;
        margin-bottom: ${isError ? '6px' : '0'};
        outline: none;
        padding: 16px 16px;
        resize: none;
        width: 100%;
        height: 148px;

        &:active,
        &:focus {
            border-color: ${isError ? colors.error : colors.grey};
        }
    `,
);

export const TextareaWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
`;
