import * as Common from 'components/_universal/Common';
import styled, { css } from 'styled-components';
import { B2 } from 'styles/Typography.styled';

export const ClickableList = styled(Common.Div)<{ isActive?: boolean }>(
    ({ theme: { colors } }) =>
        css`
            &:hover {
                ${B2} {
                    color: ${colors.hoverBlue};
                }
            }
        `,
);

export const VerticalLine = styled(Common.Div)(
    () =>
        css`
            margin-top: 64px;
            border: 1px solid white;
            height: 132px;
            width: 1px;
        `,
);

export const BarPlaceholder = styled(Common.Div)(
    () =>
        css`
            width: 580px;
            height: 1px;
        `,
);
