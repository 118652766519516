import { ToggleButton } from 'components/_universal/ToggleButton';
import { navigate } from 'gatsby';
import React, { useState } from 'react';
import * as Common from 'components/_universal/Common';
import {
    ConsentCookiesWrapper,
    CookieActionText,
    CookieButton,
    Wrapper,
} from 'components/_modals/CookiesLaw/CookiesLaw.styled';
import { COOKIES_CLICKED, COOKIES_CONSENT } from 'shared/localStorage';
import { ROUTE_PRIVACY_POLICY } from 'shared/paths';
import styled, { css } from 'styled-components';
import { B2, B3, B4 } from 'styles/Typography.styled';
import { deleteAllCookies } from 'utils/cookies';

export const HorizontalBorder = styled(Common.Div)(
    () =>
        css`
            width: 100%;
            height: 1px;
            border-top: 1px solid white;
        `,
);

export const ConsentToCookies = ({
    setIsConsentOpen,
    setIsSlideUp,
}: {
    setIsConsentOpen: (arg: boolean) => void;
    setIsSlideUp: (arg: boolean) => void;
}) => {
    const [isToggled, setIsToggled] = useState(true);

    const handleCookiesAccept = () => {
        setIsConsentOpen(false);
        localStorage.setItem(COOKIES_CONSENT, isToggled ? 'true' : 'false');
        localStorage.setItem(COOKIES_CLICKED, 'true');

        deleteAllCookies();

        if (typeof window !== 'undefined') {
            window.location.reload();
        }
    };

    const handleNavigatePrivacyPolicy = () => {
        setIsConsentOpen(false);
        setIsSlideUp(true);
        navigate(ROUTE_PRIVACY_POLICY);
    };

    return (
        <ConsentCookiesWrapper>
            <Wrapper>
                <Common.Div>
                    <B2 mb={3}>Consent to Cookies</B2>
                    <Common.Div flex="row" gap="64px" mb={1}>
                        <B3>Allow</B3>
                        <B3>Category</B3>
                    </Common.Div>
                    <HorizontalBorder mb={4} />
                    <Common.Div flex="column" gap="36px" mb={1}>
                        <Common.Div flex="row" gap="70px">
                            <ToggleButton isDisabled={true} isToggled={true} />
                            <div>
                                <B3>Functional</B3>
                                <B4>
                                    To monitor the performance of our site and
                                    to enhance your browsing experience.
                                </B4>
                            </div>
                        </Common.Div>
                        <Common.Div flex="row" gap="70px">
                            <ToggleButton
                                onClick={() => setIsToggled(!isToggled)}
                                isToggled={isToggled}
                            />
                            <div>
                                <B3>Marketing, Analytics and Ads</B3>
                                <B4>
                                    To understand user behavior in order to
                                    provide you with a more relevant browsing
                                    experience or personalize the content on our
                                    site.
                                </B4>
                            </div>
                        </Common.Div>
                    </Common.Div>
                </Common.Div>
                <HorizontalBorder />
                <B4>
                    For more information about Cookies content, please visit{' '}
                    <CookieActionText onClick={handleNavigatePrivacyPolicy}>
                        Privacy Policy/Cookies
                    </CookieActionText>
                </B4>
                <Common.Div flex="row" justifyContent="flex-end">
                    <CookieButton onClick={handleCookiesAccept}>
                        Accept
                    </CookieButton>
                </Common.Div>
            </Wrapper>
        </ConsentCookiesWrapper>
    );
};
