import styled, { css } from 'styled-components';

export const ErrorMessageWrapper = styled.div`
    display: flex;
    align-items: center;
`;

export const ErrorText = styled.p(
    ({ theme: { colors, fontSizes } }) => css`
        padding-left: 4px;
        color: ${colors.error};
        font-size: ${fontSizes.f11};
        line-height: 1;
    `,
);
