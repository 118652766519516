export const disallowedMailDomains = [
    'gmail',
    'hotmail',
    'icloud',
    'yahoo',
    'outlook',
    'yandex',
    'mail',
    'protonmail',
    'zoho',
    'aol',
    'onet',
    'interia',
    'wp',
    'o2',
    'gazeta',
    'tlen',
    't-online',
    'orange',
    'libero',
    'jourrapide',
    'mailchimp',
    'mailerlite',
    'getemail',
    'armyspy',
    'cuvox',
    'dayrep',
    'einrot',
    'fleckens',
    'gustr',
    'rhyra',
    'rhyta',
    'superrito',
    'telework',
    'teleworm',
    'flexvio',
];
