import defaultTheme from 'styles/theme';

export enum Theme {
    DEFAULT = 'DEFAULT',
}

export type ThemeType = typeof defaultTheme;
export type TextColorType = keyof ThemeType['colors'];
export type FontSizes = keyof ThemeType['fontSizes'];
export type FontWeight = keyof ThemeType['fontWeight'];

export enum ToastTypes {
    DEFAULT = 'default',
    ERROR = 'error',
    SUCCESS = 'success',
    WARNING = 'warning',
}

export interface IPageProps {
    location: {
        pathname: string;
    };
}
