import { yupResolver } from '@hookform/resolvers/yup';
import ArrowSvg from 'assets/images/_infoGraphics/arrow.svg';
import ConstiFace from 'assets/images/About/people/constiSmallFace.png';
import { Checkbox } from 'components/_inputs/Checkbox/Checkbox';
import InputPhone from 'components/_inputs/PhoneInput/InputPhone';
import { TextInput } from 'components/_inputs/TextInput/TextInput';
import { notify } from 'components/_modals/ToastNotification/ToastNotification';
import * as Common from 'components/_universal/Common';
import * as emailjs from 'emailjs-com';
import { navigate } from 'gatsby';
import React from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import {
    HorizontalLine,
    InputsWrapper,
    SmallerTextarea,
    WelcomeBox,
} from 'sections/_universal/TalkProjectForm.styled';
import {
    CheckboxInsideWrapper,
    InsideFormWrapper,
    StyledLink,
    SubmitButton,
} from 'sections/Contact/ContactForm/ContactForm.styled';
import { ROUTE_PRIVACY_POLICY, ROUTE_THANK_YOU } from 'shared/paths';
import { talkProjectValidation } from 'shared/validation';
import { B2, B4 } from 'styles/Typography.styled';
import { ToastTypes } from 'types/CommonTypes';
import { addFromMagazineField } from 'utils/cookies';
import { useFormValidation } from 'utils/hooks/useFormValidation';

export const TalkProjectForm = ({
    handleClosePopup,
}: {
    handleClosePopup: () => void;
}) => {
    const {
        control,
        handleSubmit,
        formState: { errors },
        reset,
        watch,
        setError,
    } = useForm<FieldValues>({
        mode: 'all',
        resolver: yupResolver(talkProjectValidation),
    });
    const form = watch();

    const {
        country,
        ipAddress,
        validateSubmit,
        parsePhone,
        setInputDialCountry,
    } = useFormValidation(form);

    const onSubmit = () => {
        emailjs
            .send(
                'default_service',
                'codahead_main_page_form',
                {
                    name: form.name,
                    email: form.email,
                    description: form.description,
                    phone: parsePhone(),
                    country: country,
                    ip: ipAddress,
                    ...addFromMagazineField(),
                },
                'user_AFpd8OGklEw6C76b5ygNw',
            )
            .then(
                (result) => {
                    reset({
                        email: '',
                        name: '',
                        desc: '',
                    });
                    handleClosePopup();
                    navigate(ROUTE_THANK_YOU);
                },
                (error) => {
                    console.log('Error:: ', error);
                    return notify(error.text)[ToastTypes.ERROR]();
                },
            );
    };

    return (
        <div>
            <Common.Div flex="column">
                <Common.Div flex="row" gap="28px" w="100%">
                    <InsideFormWrapper>
                        <Common.Div flex="row" mb={6}>
                            <Common.Svg src={ConstiFace} w="60px" h="60px" />
                            <HorizontalLine />
                            <WelcomeBox>
                                <Common.Div flex="row">
                                    <B4>
                                        <span style={{ fontWeight: 'bold' }}>
                                            Welcome
                                        </span>
                                        , I'm Constantin, Sales Manager at
                                        Codahead. If you want to talk to me
                                        about your idea, use the form below or
                                        write to me at: costi@codahead.com
                                    </B4>
                                </Common.Div>
                            </WelcomeBox>
                        </Common.Div>
                        <InputsWrapper
                            isError={!!errors.name || !!errors.email}
                        >
                            <TextInput
                                label="Your Name and Surname*"
                                control={control}
                                errors={errors.name}
                                name="name"
                                type={'text'}
                            />
                            <Common.Div w="100%" />
                        </InputsWrapper>
                        <InputsWrapper
                            isError={!!errors.name || !!errors.email}
                        >
                            <InputPhone
                                label="Phone number*"
                                name="phone"
                                control={control}
                                errors={errors.phone}
                                setInputDialCountry={setInputDialCountry}
                            />
                            <TextInput
                                label="Email*"
                                control={control}
                                errors={errors.email}
                                name="email"
                                type={'email'}
                            />
                        </InputsWrapper>
                        <InputsWrapper isError={!!errors.description}>
                            <SmallerTextarea
                                label="What would you like to share with us?"
                                control={control}
                                errors={errors.description}
                                name="description"
                            />
                        </InputsWrapper>
                        <CheckboxInsideWrapper>
                            <Checkbox
                                control={control}
                                isError={!!errors.cb_privacyPolicy}
                                name="cb_privacyPolicy"
                            />
                            <B2>
                                I agree with{' '}
                                <StyledLink
                                    href={ROUTE_PRIVACY_POLICY}
                                    target="_blank"
                                >
                                    Privacy Policy
                                </StyledLink>
                                *
                            </B2>
                        </CheckboxInsideWrapper>
                        <Common.Div flex="row" justifyContent="flex-end">
                            <SubmitButton
                                mt={2}
                                onClick={handleSubmit(
                                    () => validateSubmit(onSubmit, setError),
                                    (e) => console.log(e),
                                )}
                            >
                                <B2>send</B2>
                                <Common.Svg src={ArrowSvg} />
                            </SubmitButton>
                        </Common.Div>
                    </InsideFormWrapper>
                </Common.Div>
            </Common.Div>
        </div>
    );
};
