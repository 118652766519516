import LogoImg from 'assets/images/_logos/codaheadLogo.svg';
import LogoEuropeanFunds from 'assets/images/_logos/logoEuropeanFunds.svg';
import LogoGDPR from 'assets/images/_logos/logoGDPR.png';
import LogoRepublicOfPoland from 'assets/images/_logos/logoRepublicPoland.svg';
import LogoUe from 'assets/images/_logos/ue_logo.svg';
import {
    ContactUsText,
    HeaderMenuItem,
    LinkText,
    NavLink,
    StyledLink,
} from 'components/_layout/Header/Header.styled';
import { Section } from 'components/_layout/Section/Section';
import { SocialMediaIcons } from 'components/_layout/SocialMediaIcons';
import * as Common from 'components/_universal/Common';
import { navigate } from 'gatsby';
import React from 'react';
import { mediaQueries } from 'shared/breakpoints';
import { CompanyLinks, IndustriesLinks, OfferLinks } from 'shared/links';
import {
    ROUTE_EUROPEAN_FUNDS,
    ROUTE_HOME,
    ROUTE_INDUSTRIES,
    ROUTE_OFFER,
    ROUTE_PRIVACY_POLICY,
} from 'shared/paths';
import shortId from 'shortid';
import styled, { css } from 'styled-components';
import { B1, B4, StyledExternalLink } from 'styles/Typography.styled';
import { useBreakpoint } from 'utils/hooks/useBreakpoints';

export const PageFooterSeparator = styled.div(
    ({ theme: { colors } }) => css`
        height: 1px;
        background-color: ${colors.greylikeText};
        margin: 0 156px;

        ${mediaQueries.md} {
            margin: 0 32px;
        }
    `,
);

export const SmallerPaddingSection = styled(Section)(
    () => css`
        padding: 32px 156px;

        ${mediaQueries.md} {
            padding: 52px 60px;
        }

        ${mediaQueries.sm} {
            padding: 44px 32px;
        }
    `,
);

const Navigation = ({ isMobile }: { isMobile: boolean }) => {
    return (
        <Common.Div
            flex={isMobile ? 'column' : 'row'}
            justifyContent={isMobile ? 'left' : 'space-between'}
        >
            <Common.Div flex="column" mr={12} mb={isMobile ? 4 : 0}>
                <B1 mb={2}>Contact us</B1>
                <B4>Rydlówka 20,</B4>
                <B4 mb={2}>30-363 Kraków, PL</B4>
                <a href={'mailto:hi@codahead.com'}>
                    <ContactUsText>hi@codahead.com</ContactUsText>
                </a>
            </Common.Div>
            <Common.Div flex={isMobile ? 'column' : 'row'}>
                <Common.Div
                    flex="column"
                    mr={12}
                    mb={isMobile ? 4 : 0}
                    gap="16px"
                >
                    <HeaderMenuItem onClick={() => navigate(ROUTE_OFFER)}>
                        Offer
                    </HeaderMenuItem>
                    {OfferLinks.map(({ link, name }) => (
                        <NavLink key={shortId.generate()} to={link}>
                            <B4>{name}</B4>
                        </NavLink>
                    ))}
                </Common.Div>
                <Common.Div
                    flex="column"
                    mr={8}
                    mb={isMobile ? 4 : 0}
                    gap="16px"
                >
                    <HeaderMenuItem onClick={() => navigate(ROUTE_INDUSTRIES)}>
                        Industries
                    </HeaderMenuItem>
                    <Common.Div flex="row" gap="60px">
                        <Common.Div flex="column" gap="16px">
                            {IndustriesLinks.slice(0, 5).map(
                                ({ link, name }) => (
                                    <NavLink key={shortId.generate()} to={link}>
                                        <B4>{name}</B4>
                                    </NavLink>
                                ),
                            )}
                        </Common.Div>
                        <Common.Div flex="column" gap="16px">
                            {IndustriesLinks.slice(5, 10).map(
                                ({ link, name }) => (
                                    <NavLink key={shortId.generate()} to={link}>
                                        <B4>{name}</B4>
                                    </NavLink>
                                ),
                            )}
                        </Common.Div>
                    </Common.Div>
                </Common.Div>
                <Common.Div flex="column" gap="16px">
                    <B1>Company</B1>
                    {CompanyLinks.map(({ link, name, isInternal }) =>
                        isInternal ? (
                            <NavLink key={shortId.generate()} to={link}>
                                <B4>{name}</B4>
                            </NavLink>
                        ) : (
                            <LinkText
                                key={shortId.generate()}
                                onClick={() => navigate(link)}
                            >
                                {name}
                            </LinkText>
                        ),
                    )}
                </Common.Div>
            </Common.Div>
        </Common.Div>
    );
};

const TEXT_CODAHEAD = `Codahead implements a project co-financed from European Funds "Promotion of services provided by Codahead in selected foreign markets." The aim of the project is to develop the company's export activities in foreign markets.`;

export const Footer = () => {
    const isMobile = useBreakpoint('sm');
    const isMd = useBreakpoint('md');

    return (
        <Common.Div flex="column">
            <PageFooterSeparator />
            <Section variant="secondary">
                <Common.Div
                    flex={isMobile ? 'column' : 'row'}
                    justifyContent="space-between"
                    alignItems="center"
                    mb={8}
                >
                    <StyledLink to={ROUTE_HOME}>
                        <Common.Svg
                            src={LogoImg}
                            w={isMobile ? '111px' : '133px'}
                            mb={isMobile ? 4 : 0}
                        />
                    </StyledLink>
                    <SocialMediaIcons />
                </Common.Div>
                <Navigation isMobile={isMobile} />
            </Section>
            <SmallerPaddingSection>
                <Common.Div
                    flex={isMobile ? 'column' : 'row'}
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Common.Div flex={isMobile ? 'column' : 'row'}>
                        <Common.Svg
                            src={LogoEuropeanFunds}
                            w={isMobile ? '111px' : '91px'}
                            mb={isMobile ? 4 : 0}
                            mr={isMobile ? 0 : 8}
                        />
                        <Common.Svg
                            src={LogoRepublicOfPoland}
                            w={isMobile ? '111px' : '107px'}
                            mb={isMobile ? 4 : 0}
                            mr={isMobile ? 0 : 8}
                        />
                        <StyledExternalLink
                            href={ROUTE_EUROPEAN_FUNDS}
                            target="_blank"
                        >
                            <Common.Svg
                                src={LogoUe}
                                w={'137px'}
                                mb={isMobile ? 4 : 0}
                                mr={isMobile ? 0 : 8}
                            />
                        </StyledExternalLink>
                    </Common.Div>

                    {!isMd && (
                        <Common.Div>
                            <StyledExternalLink
                                href={ROUTE_EUROPEAN_FUNDS}
                                target="_blank"
                            >
                                <B4>{TEXT_CODAHEAD}</B4>
                            </StyledExternalLink>
                        </Common.Div>
                    )}
                </Common.Div>
                {isMd && (
                    <Common.Div w="100%" mt={2}>
                        <StyledExternalLink
                            href={ROUTE_EUROPEAN_FUNDS}
                            target="_blank"
                        >
                            <B4>{TEXT_CODAHEAD}</B4>
                        </StyledExternalLink>
                    </Common.Div>
                )}
            </SmallerPaddingSection>
            <Section variant="secondary" isSmall>
                <Common.Div
                    flex={isMobile ? 'column-reverse' : 'row'}
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <B4>© 2023 Codahead. All rights reserved</B4>
                    <Common.Div
                        flex="row"
                        w="250px"
                        alignItems="center"
                        mb={isMobile ? 2 : 0}
                        gap={isMobile ? '16px' : '32px'}
                        justifyContent={isMobile ? 'center' : 'flex-end'}
                    >
                        <StyledExternalLink
                            href={ROUTE_PRIVACY_POLICY}
                            target="_blank"
                        >
                            <B4>Privacy Policy</B4>
                        </StyledExternalLink>
                        <StyledLink to={ROUTE_PRIVACY_POLICY} target="_blank">
                            <Common.Svg src={LogoGDPR} w="53px" h="53px" />
                        </StyledLink>
                    </Common.Div>
                </Common.Div>
            </Section>
        </Common.Div>
    );
};
