import React from 'react';

interface CloseIconProps {
    color: string;
}

export const CloseIcon = ({ color }: CloseIconProps) => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path
                d="M6 6L17.3137 17.3137"
                stroke={color}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M5.99977 17.3137L17.3135 6"
                stroke={color}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export const MotherWebisteCloseIcon = () => {
    return (
        <svg
            width="14"
            height="13"
            viewBox="0 0 14 13"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M6.69495 5.03664L11.8644 0.140381L13.3411 1.53903L8.17163 6.43529L13.3411 11.3316L11.8644 12.7302L6.69495 7.83394L1.52551 12.7302L0.0488281 11.3316L5.21827 6.43529L0.0488281 1.53903L1.52551 0.140381L6.69495 5.03664Z"
                fill="#09121F"
            />
        </svg>
    );
};
