import {
    PROJECT_FISHIAL,
    PROJECT_HOTELS,
    PROJECT_MOBD,
    PROJECT_NUUS,
    PROJECT_RESPECT,
    PROJECT_SPOTON,
    PROJECT_TICK,
    PROJECT_TULERIE,
    PROJECT_UDACITY,
    PROJECT_VOICE,
} from 'shared/data/Project.data';

export const ROUTE_HOME = '/';
export const ROUTE_OFFER = '/offer';
export const ROUTE_OFFER_SOFTWARE_TAB = '/offer#software';
export const ROUTE_OFFER_DEEPTECH_TAB = '/offer#deeptech';
export const ROUTE_OFFER_HARDWARE_TAB = '/offer#hardware';
export const ROUTE_404 = '/404';
export const ROUTE_ABOUT = '/about';
export const ROUTE_CONTACT = '/contact';
export const ROUTE_CASE_STUDIES = '/case-studies';
export const ROUTE_INDUSTRIES = '/industries';
export const ROUTE_BLOG = '/blog';
export const ROUTE_CAREER = '/career';
export const ROUTE_EUROPEAN_FUNDS = '/european-funds';
export const ROUTE_JOB_OFFER = '/job-offer';
export const ROUTE_JOIN_US = '/join-us';
export const ROUTE_THANK_YOU = '/thank-you';
export const ROUTE_OTHER_INQUIRIES = '/other-inquiries';
export const ROUTE_PRIVACY_POLICY = '/privacy-policy';

// case studies projects
export const ROUTE_CASE_STUDIES_FISHIAL = `${ROUTE_CASE_STUDIES}/${PROJECT_FISHIAL}`;
export const ROUTE_CASE_STUDIES_SPOTON = `${ROUTE_CASE_STUDIES}/${PROJECT_SPOTON}`;
export const ROUTE_CASE_STUDIES_TULERIE = `${ROUTE_CASE_STUDIES}/${PROJECT_TULERIE}`;
export const ROUTE_CASE_STUDIES_TICK_PREDICT = `${ROUTE_CASE_STUDIES}/${PROJECT_TICK}`;
export const ROUTE_CASE_STUDIES_VOICE = `${ROUTE_CASE_STUDIES}/${PROJECT_VOICE}`;
export const ROUTE_CASE_STUDIES_MOBD = `${ROUTE_CASE_STUDIES}/${PROJECT_MOBD}`;
export const ROUTE_CASE_STUDIES_NUUS = `${ROUTE_CASE_STUDIES}/${PROJECT_NUUS}`;
export const ROUTE_CASE_STUDIES_RESPECT = `${ROUTE_CASE_STUDIES}/${PROJECT_RESPECT}`;
export const ROUTE_CASE_STUDIES_UDACITY = `${ROUTE_CASE_STUDIES}/${PROJECT_UDACITY}`;
export const ROUTE_CASE_STUDIES_HOTELS = `${ROUTE_CASE_STUDIES}/${PROJECT_HOTELS}`;

// external paths
export const EXTERNAL_ROUTE_ONBOARDING =
    'https://sites.google.com/codahead.com/onboarding?pli=1';

// map for setting page title in browser
export const ROUTE_TO_PAGE_NAME_MAP: Record<string, string> = {
    [ROUTE_HOME]: 'Home',
    [ROUTE_OFFER]: 'Offer',
    [ROUTE_404]: '404',
    [ROUTE_ABOUT]: 'About',
    [ROUTE_CASE_STUDIES_SPOTON]: 'Spoton',
    [ROUTE_CASE_STUDIES_FISHIAL]: 'Fishial',
    [ROUTE_CASE_STUDIES_TICK_PREDICT]: 'Tick Predict',
    [ROUTE_CASE_STUDIES_TULERIE]: 'Tulerie',
    [ROUTE_CASE_STUDIES_VOICE]: 'Voice',
    [ROUTE_CASE_STUDIES_MOBD]: 'MOBD',
    [ROUTE_CASE_STUDIES_NUUS]: 'NUUS',
    [ROUTE_CASE_STUDIES_RESPECT]: 'Respect Energy',
    [ROUTE_CASE_STUDIES_UDACITY]: 'Udacity',
    [ROUTE_CASE_STUDIES_HOTELS]: 'NDA',
    [ROUTE_BLOG]: 'Blog',
    [ROUTE_CAREER]: 'Career',
    [ROUTE_EUROPEAN_FUNDS]: 'European Funds',
    [ROUTE_JOB_OFFER]: 'Job Offer',
    [ROUTE_JOIN_US]: 'Join Us',
    [ROUTE_THANK_YOU]: 'Thank you',
    [ROUTE_OTHER_INQUIRIES]: 'Other Inquiries',
    [ROUTE_PRIVACY_POLICY]: 'Privacy Policy',
};
