import * as Common from 'components/_universal/Common';
import React from 'react';
import { mediaQueries } from 'shared/breakpoints';
import { GoodFirmsIcon } from 'shared/svg/blogIcons';
import {
    DesignRushIcon,
    DribbleIcon,
    FooterClutchIcon,
    FooterFacebookIcon,
    FooterInstagramIcon,
    FooterLinkedinIcon,
} from 'shared/svg/footerIcon';
import styled, { css } from 'styled-components';
import { B4 } from 'styles/Typography.styled';
import { useBreakpoint } from 'utils/hooks/useBreakpoints';

export const StyledLinkIcon = styled.a(
    ({ theme: { colors } }) =>
        css`
            align-items: center;
            cursor: pointer;
            display: flex;

            &:hover {
                * {
                    fill: ${colors.hoverBlue};
                }
            }
        `,
);

export const StyledHoverIcon = styled.svg(
    ({ theme: { colors } }) =>
        css`
            &:hover {
                fill: ${colors.hoverBlue};
            }
        `,
);

export const IconsWrapper = styled.div`
    display: flex;
    gap: 30px;
    justify-content: flex-end;

    ${mediaQueries.sm} {
        gap: 16px;
        flex-direction: column;
    }
`;

export const VerticalSeparator = styled.div(
    ({ theme: { colors } }) =>
        css`
            height: 18px;
            width: 1px;
            background: ${colors.text};
        `,
);

export const SocialMediaIcons = ({ className }: { className?: string }) => {
    const isSm = useBreakpoint('sm');
    return (
        <IconsWrapper className={className}>
            <Common.Div flex="row" gap="16px" alignItems="center">
                <B4>View our profile on DesignRush</B4>
                {!isSm && <VerticalSeparator />}
                <StyledLinkIcon
                    target={'_blank'}
                    href={'https://www.designrush.com/agency/profile/codahead'}
                >
                    <DesignRushIcon />
                </StyledLinkIcon>
            </Common.Div>
            <Common.Div flex="row" gap="30px">
                <StyledLinkIcon
                    target={'_blank'}
                    href={'https://clutch.co/profile/codahead'}
                >
                    <FooterClutchIcon />
                </StyledLinkIcon>
                <StyledLinkIcon
                    target={'_blank'}
                    href={'https://dribbble.com/Codahead'}
                >
                    <DribbleIcon />
                </StyledLinkIcon>
                <StyledLinkIcon
                    target={'_blank'}
                    href={'https://www.linkedin.com/company/codahead/'}
                >
                    <FooterLinkedinIcon />
                </StyledLinkIcon>
                <StyledLinkIcon
                    target={'_blank'}
                    href={'https://www.facebook.com/codahead/'}
                >
                    <FooterFacebookIcon />
                </StyledLinkIcon>
                <StyledLinkIcon
                    target={'_blank'}
                    href={'https://www.instagram.com/codahead_/'}
                >
                    <FooterInstagramIcon />
                </StyledLinkIcon>
                <StyledLinkIcon
                    href={`https://www.goodfirms.co/company/codahead`}
                    target="_blank"
                    title="Top Software Development Company"
                >
                    <GoodFirmsIcon />
                </StyledLinkIcon>
            </Common.Div>
        </IconsWrapper>
    );
};
