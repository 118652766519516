import bgEnergy from 'assets/images/Industries/headerEnergy.png';
import bgFishial from 'assets/images/Industries/headerFishial.png';
import bgHealthcare from 'assets/images/Industries/headerHealthcare.png';
import bgHotels from 'assets/images/Industries/headerHotels.png';
import bgMobd from 'assets/images/Industries/headerMobd.png';
import bgNuus from 'assets/images/Industries/headerNUUS.png';
import bgSpoton from 'assets/images/Industries/headerSpoton.png';
import bgTickPredict from 'assets/images/Industries/headerTickPredict.png';
import bgTulerie from 'assets/images/Industries/headerTulerie.png';
import bgUdacity from 'assets/images/Industries/headerUdacity.png';

import * as Common from 'components/_universal/Common';
import { navigate } from 'gatsby';
import { useTheme } from 'layout/ThemeContext';
import React from 'react';
import { ROUTE_INDUSTRIES } from 'shared/paths';
import styled, { css } from 'styled-components';
import { B3 } from 'styles/Typography.styled';
import { useBreakpoint } from 'utils/hooks/useBreakpoints';

const industryItems = [
    { name: 'Retail', src: bgSpoton, href: 'retail' },
    { name: 'Community', src: bgFishial, href: 'community' },
    { name: 'Financial', src: bgTickPredict, href: 'financial' },
    { name: 'Entertainment', src: bgTulerie, href: 'entertainment' },
    { name: 'Healthcare', src: bgHealthcare, href: 'healthcare' },
    { name: 'Automotive', src: bgMobd, href: 'automotive' },
    { name: 'Sports', src: bgNuus, href: 'sports' },
    { name: 'Energy', src: bgEnergy, href: 'energy' },
    { name: 'Information Technology', src: bgUdacity, href: 'it' },
    { name: 'Hospitality', src: bgHotels, href: 'hospitality' },
];

export const IndustryItem = styled(Common.Div)<{ bgSrc: string }>(
    ({ bgSrc }) =>
        css`
            display: flex;
            justify-content: center;
            align-items: center;
            background-image: url(${bgSrc});
            background-size: cover;
            background-repeat: no-repeat;
            height: 80px;
        `,
);

export const IndustryGrid = styled(Common.Div)(
    () =>
        css`
            display: grid;
            grid-column-gap: 16px;
            grid-row-gap: 16px;
            grid-template-columns: repeat(2, 1fr);

            @media (min-width: 650px) {
                grid-template-columns: repeat(3, 1fr);
            }

            @media (min-width: 1060px) {
                grid-template-columns: repeat(4, 1fr);
            }

            @media (min-width: 1400px) {
                grid-template-columns: repeat(5, 1fr);
            }
        `,
);

export const TabIndustries = ({ isActive }: { isActive: boolean }) => {
    const isLg = useBreakpoint('lg');
    const { setHeaderMenuIsOpen } = useTheme();

    return (
        <IndustryGrid
            mt={4}
            ml={isLg ? 0 : 6.5}
            mb={isLg ? 4 : 2}
            hidden={!isActive}
        >
            {industryItems.map(({ name, src, href }) => {
                return (
                    <IndustryItem
                        bgSrc={src}
                        key={name}
                        onClick={() => {
                            setHeaderMenuIsOpen(false);
                            navigate(`${ROUTE_INDUSTRIES}/#${href}`);
                        }}
                    >
                        <B3 align="center">{name}</B3>
                    </IndustryItem>
                );
            })}
        </IndustryGrid>
    );
};
