import { ErrorMessage } from 'components/_inputs/ErrorMessage/ErrorMessage';
import React, { RefObject, useRef } from 'react';
import PhoneInput, { PhoneInputProps } from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import {
    Controller,
    Path,
    FieldValues,
    Control,
    FieldError,
    FieldErrors,
} from 'react-hook-form';
import styled, { css } from 'styled-components';

import * as Common from 'components/_universal/Common';
import { B2 } from 'styles/Typography.styled';

interface IInputTextProps<T extends FieldValues> {
    name: Path<T>;
    label?: string;
    disabled?: boolean;
    control: Control;
    errors?: FieldError | FieldErrors;
    setInputDialCountry: (code: string) => void;
}

export const StyledPhoneInput = styled(PhoneInput)<{
    isError?: boolean;
    ref?: RefObject<PhoneInputProps>;
}>(
    ({ theme: { colors }, isError }) => css`
        margin-bottom: 6px;
        border: 1px solid ${isError ? colors.error : colors.white};
    `,
);

export const PhoneWrapper = styled(Common.Div)<{
    isError?: boolean;
}>(
    ({ theme: { colors } }) => css`
        // element selected
        .react-tel-input .country-list .country.highlight {
            background: ${colors.buttons};
        }

        // hovering element lists
        .react-tel-input .country-list .country:hover {
            background: ${colors.buttons};
        }

        // dial-codes inside list
        .react-tel-input .country-list .country .dial-code {
            color: ${colors.text};
        }

        // flag selected
        .react-tel-input .flag-dropdown.open .selected-flag {
            background: ${colors.buttons};
        }

        // hovering the flag
        .react-tel-input .selected-flag:hover {
            background: ${colors.buttons};
        }
    `,
);

const InputPhone = <T extends FieldValues>({
    name,
    control,
    label = '',
    disabled = false,
    errors,
    setInputDialCountry,
    ...rest
}: IInputTextProps<T>) => {
    const phoneInputRef = useRef<
        PhoneInputProps & { getCountryData: () => { name: string } }
    >(null);

    const handleChangeInput = (
        value: string,
        onChange: (...event: any[]) => void,
    ) => {
        if (phoneInputRef.current) {
            setInputDialCountry(phoneInputRef.current.getCountryData().name);
            onChange(value);
        }
    };

    return (
        <Controller
            name={name}
            control={control}
            render={({ field: { onChange, value }, formState: { errors } }) => {
                const error = errors[name] as { message: string };

                return (
                    <Common.Div flex="column" w="100%" {...rest}>
                        {label && <B2 mb={2}>{label}</B2>}
                        <PhoneWrapper>
                            <StyledPhoneInput
                                ref={phoneInputRef}
                                isError={!!error}
                                value={value}
                                disabled={disabled}
                                onChange={(value) =>
                                    handleChangeInput(value, onChange)
                                }
                                placeholder=""
                                buttonStyle={{
                                    background: 'none',
                                    border: 'none',
                                    borderRadius: 0,
                                }}
                                dropdownStyle={{
                                    background: '#424242',
                                    border: 'none',
                                    borderRadius: 0,
                                }}
                                inputStyle={{
                                    background: 'none',
                                    borderRadius: 0,
                                    color: 'white',
                                    border: '1px solid white',
                                    fontSize: '16px',
                                    height: '50px',
                                    width: '100%',
                                    borderColor: '#00000080',
                                }}
                            />
                        </PhoneWrapper>

                        {error && <ErrorMessage error={error} />}
                    </Common.Div>
                );
            }}
        />
    );
};
export default InputPhone;
