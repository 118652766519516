export const ProjectLinks = [
    { link: '/fishial', name: 'fishial.ai' },
    { link: '/spoton', name: 'spoton' },
    { link: '/tickpredict', name: 'tickpredict' },
    { link: '/tulerie', name: 'tulerie' },
    { link: '/voice', name: 'aivoice' },
    { link: '/mobd', name: 'mobd' },
    {
        link: '/nuus',
        name: 'nuus',
    },
    { link: '/respect', name: 'respect energy' },
    { link: '/udacity', name: 'udacity' },
    { link: '/hotels', name: 'hotel reservation system' },
];

export const Links = [
    { link: '/', name: 'Home' },
    { link: '/services/', name: 'Services' },
    { link: '/about', name: 'About' },
    { link: '/career', name: 'Career' },
    { link: '/blog', name: 'Blog' },
    { link: '/contact', name: 'Contact' },
    {
        link: '/other-inquiries',
        name: 'Other Inquiries',
    },
    { link: '/join-us', name: 'Join Us' },
    { link: '/thank-you', name: 'Thank You' },
    { link: '/industries', name: 'Industries' },
    { link: '/case-studies', name: 'Case Studies' },
    ...ProjectLinks,
];

export const OfferLinks = [
    { link: '/offer#software', name: 'Software', key: 'software' },
    { link: '/offer#deeptech', name: 'DeepTech', key: 'deeptech' },
    { link: '/offer#hardware', name: 'Hardware', key: 'hardware' },
];

export const IndustriesLinks = [
    { link: '/industries#retail', name: 'Retail' },
    { link: '/industries#community', name: 'Community' },
    { link: '/industries#financial', name: 'Financial' },
    { link: '/industries#entertainment', name: 'Entertainment' },
    { link: '/industries#healthcare', name: 'Healthcare' },
    { link: '/industries#automotive', name: 'Automotive' },
    { link: '/industries#sports', name: 'Sports' },
    { link: '/industries#energy', name: 'Energy' },
    { link: '/industries#it', name: 'Information Technology' },
    { link: '/industries#hospitality', name: 'Hospitality' },
];

export const CompanyLinks = [
    { link: '/about', name: 'About', key: 'about', isInternal: true },
    { link: '/career', name: 'Career', key: 'career', isInternal: true },
    { link: '/blog', name: 'Blog', key: 'blog', isInternal: true },
    {
        link: 'https://sites.google.com/codahead.com/onboarding?pli=1',
        name: 'Onboarding',
        key: 'onboarding',
        isInternal: false,
    },
    { link: '/contact', name: 'Contact', key: 'contact', isInternal: true },
];

export const TAKE_THIS_STEP_ID = 'take-this-step-id';
