import * as Common from 'components/_universal/Common';
import { mediaQueries } from 'shared/breakpoints';
import styled, { css } from 'styled-components';

export const InsideFormWrapper = styled.form`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

export const InputsWrapper = styled(Common.Div)<{ isError: boolean }>(
    ({ isError }) =>
        css`
            display: flex;
            gap: 28px;
            justify-content: space-between;
            margin-bottom: ${isError ? '20' : '48'}px;
            max-width: 610px;

            ${mediaQueries.xs} {
                margin-bottom: ${isError ? '20' : '38'}px;
                flex-direction: column;
            }
        `,
);

export const CheckboxInsideWrapper = styled(Common.Div)(
    () =>
        css`
            align-items: center;
            display: flex;
            white-space: nowrap;
        `,
);

export const StyledLink = styled.a(
    ({ theme: { colors } }) =>
        css`
            color: ${colors.white};
            text-decoration: underline;
        `,
);

export const SubmitButton = styled(Common.Div)(
    ({ theme: { colors, spacing } }) =>
        css`
            background: ${colors.buttons};
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: ${spacing(1)} ${spacing(3)};
            width: min(255px, 100%);
            height: 41px;
            margin-bottom: ${spacing(4)};

            p {
                font-size: 14px;
            }

            ${mediaQueries.xs} {
                max-width: none;
                width: 100%;
            }
        `,
);
