import React from 'react';
import 'react-toastify/dist/ReactToastify.css';
import {
    CloseButton,
    StyledToastContainer,
} from 'src/components/_modals/ToastNotification/ToastNotification.styled';
import { CloseIcon } from 'shared/svg/closeIcon';
import { toast } from 'react-toastify';
import { ToastProps } from 'react-toastify/dist/types';
import { ToastTypes } from 'types/CommonTypes';

export const notify = (contentText: React.ReactNode | string) => {
    return {
        [ToastTypes.ERROR]: () => toast.error(contentText),
        [ToastTypes.SUCCESS]: () => toast.success(contentText),
    };
};

export const ToastNotification = () => {
    const customCloseButton = ({ closeToast }: ToastProps) => {
        return (
            <CloseButton onClick={closeToast}>
                <CloseIcon color="white" />
            </CloseButton>
        );
    };

    return (
        <StyledToastContainer
            //@ts-ignore
            closeButton={customCloseButton}
            hideProgressBar={true}
            position={'bottom-left'}
            autoClose={5000000}
        />
    );
};
