import styled, { css } from 'styled-components';
import { Link } from 'gatsby';
import { PrimaryButton } from 'styles/Typography.styled';
import { mediaQueries } from 'shared/breakpoints';

const slideUpStyles = css`
    bottom: 32px;
    height: 278px;

    ${mediaQueries.sm} {
        bottom: 104px;
    }

    @media (max-width: 400px) {
        bottom: 164px;
    }
`;

export const CookieOuterWrapper = styled.div<{ isSlideUp: boolean }>(
    ({ isSlideUp }) => css`
        height: 0;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        position: fixed;
        text-align: center;
        transition: all 1.2s ease-in-out;
        z-index: 999999999;
        width: min(1100px, calc(100vw - 64px));

        ${isSlideUp && slideUpStyles}
    `,
);

export const Wrapper = styled.div(
    ({ theme: { colors } }) => css`
        background-color: ${colors.section};
        box-shadow: 0 10px 20px rgba(2, 0, 0, 0.5);
        display: flex;
        gap: 36px;
        padding: 42px;
        position: relative;
        flex-direction: column;

        ${mediaQueries.sm} {
            padding: 16px;
        }
    `,
);

export const CookieActionText = styled.p(
    ({ theme: { colors, fontWeight } }) => css`
        color: ${colors.white};
        display: inline-block;
        font-weight: ${fontWeight.medium};
        text-decoration: underline;
        margin-left: 6px;
        cursor: pointer;

        &:hover {
            color: ${colors.mainBlue};
        }
    `,
);

export const CookieLink = styled(Link)(
    ({ theme: { colors, fontWeight } }) => css`
        color: ${colors.white};
        display: inline-block;
        font-weight: ${fontWeight.medium};
        text-decoration: underline;
        margin-left: 4px;

        &:hover {
            color: ${colors.mainBlue};
        }
    `,
);

const CookieButtonTemplate = styled(PrimaryButton)(
    ({ theme: { colors, fontSizes } }) => css`
        height: 45px;
        width: 190px;
        color: ${colors.white};
        display: inline-block;
        padding: 0;
        font-size: ${fontSizes.f16};

        &:hover {
            background: white;
            color: ${colors.section};
        }

        ${mediaQueries.sm} {
            font-size: ${fontSizes.f12};
            width: 100%;
        }
    `,
);

export const CookieButton = styled(CookieButtonTemplate)(
    ({ theme: { colors } }) => css`
        background: ${colors.buttons};
    `,
);

export const ManageButton = styled(CookieButtonTemplate)(
    ({ theme: { colors } }) => css`
        background: transparent;
        border: 1px solid ${colors.white};
    `,
);

export const ConsentCookiesWrapper = styled.div(
    () => css`
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        position: fixed;
        text-align: center;
        transition: all 1.2s ease-in-out;
        z-index: 999999999;
        width: min(730px, calc(100vw - 64px));
    `,
);
