import { Textarea } from 'components/_inputs/Textarea/Textarea';
import * as Common from 'components/_universal/Common';
import { mediaQueries } from 'shared/breakpoints';
import styled, { css } from 'styled-components';

export const WelcomeBox = styled(Common.Div)(
    ({ theme: { colors } }) =>
        css`
            border: 1px solid ${colors.text};
            display: flex;
            justify-content: left;
            align-items: center;
            padding: 16px;
        `,
);

export const HorizontalLine = styled(Common.Div)(
    ({ theme: { colors } }) =>
        css`
            display: flex;
            align-items: center;
            margin-top: 32px;
            background: ${colors.text};
            width: 32px;
            height: 1px;

            ${mediaQueries.sm} {
                width: 32px;
            }
        `,
);

export const InputsWrapper = styled(Common.Div)<{ isError?: boolean }>(
    ({ isError }) =>
        css`
            display: flex;
            gap: 28px;
            justify-content: space-between;
            margin-bottom: ${isError ? '16' : '16'}px;

            ${mediaQueries.sm} {
                flex-direction: column;
            }
        `,
);

export const SmallerTextarea = styled(Textarea)(
    () =>
        css`
            height: 54px;
        `,
);
