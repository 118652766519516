import * as Common from 'components/_universal/Common';
import { mediaQueries } from 'shared/breakpoints';
import styled, { css } from 'styled-components';

export const TalkFixedTray = styled(Common.Div)<{
    isPageScrolledDown?: boolean;
}>(
    ({ theme: { colors }, isPageScrolledDown }) =>
        css`
            position: fixed;
            z-index: 99999999;
            right: 144px;
            bottom: ${isPageScrolledDown ? '531px' : '94px'};
            max-width: 413px;
            height: 58px;
            background: ${colors.section};
            cursor: pointer;
            padding: 0 24px;
            width: 186px;

            -webkit-box-shadow: 3px 2px 10px 6px rgba(0, 0, 0, 0.26);
            -moz-box-shadow: 3px 2px 10px 6px rgba(0, 0, 0, 0.26);
            box-shadow: 3px 2px 10px 6px rgba(0, 0, 0, 0.26);

            p {
                color: ${colors.white};
            }

            ${mediaQueries.md} {
                right: 32px;
                bottom: 96px;
            }
        `,
);

export const TalkContent = styled(Common.Div)(
    ({ theme: { fontFamily } }) =>
        css`
            width: 100%;
            height: 100%;
            z-index: 99999999;
            right: 48px;
            bottom: 80px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-family: ${fontFamily.heading};
        `,
);

export const TalkModal = styled(Common.Div)(
    ({ theme: { colors } }) =>
        css`
            padding: 32px;
            position: fixed;
            z-index: 99999999;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            background: ${colors.section};
            cursor: pointer;
            box-sizing: border-box;

            ${mediaQueries.ipadH} {
                padding: 32px;
            }

            ${mediaQueries.md} {
                top: 16px;
                left: 16px;
                transform: translate(0, 0);
                height: calc(100vh - 32px);
                overflow-y: scroll;
                overflow-x: hidden;
                padding: 16px;
                width: calc(100vw - 32px);
                h3 {
                    word-wrap: break-word;
                }
            }
        `,
);

export const OverlayMask = styled(Common.Div)`
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.55);
    z-index: 99999;
`;
