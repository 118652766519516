import { useEffect, useState } from 'react';

export function useDetectIpCountry() {
    const [country, setCountry] = useState();
    const [ipAddress, setIpAddress] = useState();

    // Detect country and ip of the user
    useEffect(() => {
        fetch('https://api.ip2loc.com/ZxROEGl8mm2Xt8Tmr8BzTCXy5ptoRiYx/detect')
            .then((response) => response.json())
            .then((response) => {
                if (response.success) {
                    const country =
                        response.location && response.location.country
                            ? response.location.country.name
                            : 'unknown';
                    const ipAddress = response.connection
                        ? response.connection.ip
                        : '-';
                    setCountry(country);
                    setIpAddress(ipAddress);
                }
            });
    }, []);

    return { country, ipAddress };
}
