import React from 'react';

interface ErrorIconProps {
    width: string;
    height: string;
}

export const ErrorIcon = ({ width, height }: ErrorIconProps) => {
    return (
        <svg width={width} height={height} viewBox="0 0 16 16" fill="none">
            <path
                d="M7.99967 14.6673C11.6663 14.6673 14.6663 11.6673 14.6663 8.00065C14.6663 4.33398 11.6663 1.33398 7.99967 1.33398C4.33301 1.33398 1.33301 4.33398 1.33301 8.00065C1.33301 11.6673 4.33301 14.6673 7.99967 14.6673Z"
                stroke="#C52323"
                strokeWidth="1.33333"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M8 5.33398V8.66732"
                stroke="#C52323"
                strokeWidth="1.33333"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M7.99609 10.666H8.00208"
                stroke="#C52323"
                strokeWidth="1.33333"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};
