import { THeaderTabType } from 'components/_layout/Header/Header';
import {
    ExpandableDiv,
    HeaderMenuItem,
} from 'components/_layout/Header/Header.styled';
import { TabCompany } from 'components/_layout/Header/TabCompany';
import { TabIndustries } from 'components/_layout/Header/TabIndustries';
import { TabOffer } from 'components/_layout/Header/TabOffer';
import * as Common from 'components/_universal/Common';
import { navigate } from 'gatsby';
import { useTheme } from 'layout/ThemeContext';
import React from 'react';
import { ROUTE_CASE_STUDIES } from 'shared/paths';

export const VerticalMenu = ({
    isVisible,
    activeTab,
    handleTabSelect,
}: {
    isVisible?: boolean;
    activeTab: THeaderTabType;
    handleTabSelect: (arg: THeaderTabType) => void;
}) => {
    const { setHeaderMenuIsOpen } = useTheme();

    return (
        <Common.Div flex="column" mt={8} hidden={!isVisible}>
            <HeaderMenuItem
                onClick={() => handleTabSelect('OFFER')}
                isActive={activeTab === 'OFFER'}
            >
                Offer
            </HeaderMenuItem>
            <ExpandableDiv isOpen={activeTab === 'OFFER'}>
                <TabOffer isActive={activeTab === 'OFFER'} />
            </ExpandableDiv>

            <HeaderMenuItem
                onClick={() => handleTabSelect('INDUSTRIES')}
                isActive={activeTab === 'INDUSTRIES'}
            >
                Industries
            </HeaderMenuItem>
            <ExpandableDiv isOpen={activeTab === 'INDUSTRIES'}>
                <TabIndustries isActive={activeTab === 'INDUSTRIES'} />
            </ExpandableDiv>

            <HeaderMenuItem
                onClick={() => {
                    setHeaderMenuIsOpen(false);
                    navigate(ROUTE_CASE_STUDIES);
                }}
                isActive={activeTab === 'CASE_STUDIES'}
            >
                Case studies
            </HeaderMenuItem>
            <HeaderMenuItem
                onClick={() => handleTabSelect('COMPANY')}
                isActive={activeTab === 'COMPANY'}
            >
                Company
            </HeaderMenuItem>
            <ExpandableDiv isOpen={activeTab === 'COMPANY'}>
                <TabCompany isActive={activeTab === 'COMPANY'} />
            </ExpandableDiv>
        </Common.Div>
    );
};
