import React from 'react';
import { ErrorIcon } from 'shared/svg/error';
import {
    ErrorText,
    ErrorMessageWrapper,
} from 'src/components/_inputs/ErrorMessage/ErrorMessage.styled';

interface ErrorMessageProps {
    error?: any;
}

export const ErrorMessage = ({ error }: ErrorMessageProps) => {
    return (
        <ErrorMessageWrapper>
            <ErrorIcon width="22" height="22" />
            <ErrorText>{error?.message}</ErrorText>
        </ErrorMessageWrapper>
    );
};
