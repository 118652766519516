import { useState } from 'react';
import { FieldValues } from 'react-hook-form';
import { countryPrefixes } from 'shared/countryPrefixes';
import { useDetectIpCountry } from 'utils/hooks/useDetectIpCountry';

export const useFormValidation = (form: FieldValues) => {
    const { country, ipAddress } = useDetectIpCountry();
    const matchingCountry = countryPrefixes.find((c) => c.name === country);
    const dialCode = matchingCountry?.dial_code;

    const [inputDialCountry, setInputDialCountry] = useState('');

    // Phone Validation against bots
    const validateSubmit = (onValidSubmit: () => void, setError: any) => {
        if (matchingCountry && matchingCountry.name === inputDialCountry) {
            onValidSubmit();
        } else {
            setError('phone', {
                message: 'Country prefix does not match with your country',
            });
        }
    };

    const parsePhone = () => {
        if (dialCode && form.phone) {
            const codeLength = dialCode.length - 1;
            const strippedPhone = form.phone.slice(codeLength);
            return `${dialCode} ${strippedPhone}`;
        }
    };

    return {
        country,
        ipAddress,
        validateSubmit,
        parsePhone,
        setInputDialCountry,
    };
};
