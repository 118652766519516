import styled, { css } from 'styled-components';

export const Input = styled.input<{
    isError?: boolean;
}>(
    ({
        theme: { colors, fontFamily, fontSizes, fontWeight, letterSpacing },
        isError,
    }) => css`
        background: none;
        border: 1px solid ${isError ? colors.error : colors.white};
        color: ${colors.white};
        font-family: ${fontFamily.secondary};
        font-size: ${fontSizes.f16};
        font-weight: ${fontWeight.regular};
        letter-spacing: ${letterSpacing.s};
        line-height: 13px;
        margin-bottom: ${isError ? '6px' : '0'};
        outline: none;
        padding: 16px 16px;
        width: 100%;

        &::-webkit-input-placeholder,
        &::-ms-input-placeholder {
            color: ${colors.placeholder};
        }

        &:active,
        &:focus {
            border-color: ${isError ? colors.error : colors.placeholder};
        }
    `,
);

export const InputWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
`;
